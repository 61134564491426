<template>
  <div class="Awarp">
    <dashCard
      class="operationAm2"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('operationAm2_rank','工单量排行榜', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div
        ref="text_modle13"
        style="width: 100%; height: 100%"
        class="OperationCom3"
      >
        <!-- <div style="display: flex; width: 100%;">
        <div style="width: 30%;">
          <el-select v-model="company" placeholder="请选择公司" class="company" @change="gsChange">
            <el-option v-for="item in options1" :key="item.CODE" :label="item.FULLNAME" :value="item.CODE">
            </el-option>
          </el-select>
        </div>
        <div style="width: 30%;">
          <el-select v-model="site" placeholder="请选择站点" class="company" @change="siteChange">
            <el-option v-for="item in options2" :key="item.CODE" :label="item.FULLNAME" :value="item.CODE">
            </el-option>
          </el-select>
        </div>
        <div style="width: 30%;">
          <el-date-picker value-format="yyyy-MM-dd" v-model="value1" type="daterange" range-separator="→" start-placeholder="开始日期" @change="timeChange"
                          end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div> -->
        <!-- <div ref="main_modle13" style="width: 45%; height: 240px; margin-top: 5%;"></div> -->
        <div class="card-content2" style="height: calc(100% - 40px)">
          <chartBoard
            ref="main_modle13"
            :option="chartOption1"
            style="height: 100%; width: 100%"
          />
        </div>
        <!--        <div style="position: absolute; top: 38%; right: 6%; height: 240px;">-->
        <!--            <div v-for="(item, index) in newData" :key="index" style="margin-bottom: 29%;">-->
        <!--                <span style="color: rgba(0,0,0,0.45); font-size: 12px;">同比</span>-->
        <!--                <i v-if="item.trend == 1" class="el-icon-caret-top" style="color:#F53F3F; font-size: 12px;"></i>-->
        <!--                <i v-if="item.trend == 2" class="el-icon-caret-bottom" style="color:#F53F3F; font-size: 12px;"></i>-->
        <!--                 <span style="color:#F53F3F;">{{ item.num }}</span></div>-->
        <!--        </div>-->
      </div>
      <div style="position: absolute; bottom: 10px; right: 30px">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="count"
          :page-size="page.limit"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <operationAm2
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "operationAm2",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: [], //选中的项目
        ITEM2: "", //选中的项目
        ITEM3: [], //选中的项目
        ITEM4: "", //选中的项目
        ITEM5: [], //选中的项目
      },
      panelOpen: false,
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      vm: null,
      dialogCategory: false,
      chartOption1: {},
      alarmWarningData1: [], //机械特性左侧-legendData
      alarmWarningData2: [], //机械特性左侧-xAxisData
      alarmWarningData3: [], //机械特性左侧-seriesData
      options1: [],
      options2: [],
      value1: [],
      company: "",
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
      
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      count: 1,
      page: {
        limit: 5,
        page: 1,
      },
      site: "",
      newData: [],
    };
  },
  computed: {
    // 当前登录用户信息
    currentuserinfo() {
      return this.$store.state.currentuserinfo;
    },
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    onChange(value) {
      console.log("onChange", value, this.searchObj);
      this.getData();
      this.$forceUpdate();
    },
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          queryType: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    currentChange(page) {
      this.page.page = page;
      this.getData();
    },
    getGSList() {
      this.$axios({
        url: Config.dev_url + "/api-apps/app/DEPT/list",
        method: "post",
        data: {
          DEPTTYPE: "公司",
        },
      }).then((res) => {
        this.options1 = res.data.data;
      });
    },
    gsChange() {
      this.site = "";
      this.$axios({
        url: Config.dev_url + "/api-apps/app/DEPT/list",
        method: "post",
        data: {
          DEPTTYPE: "站点",
          pcode: this.company,
        },
      }).then((res) => {
        this.options2 = res.data.data;
      });
    },
    getData() {
      let data = {
        equipment: this.currentSelectDeptInfo.CODE,
        // equipment: this.site,
        // startTime: this.value1[0] + " 00:00:00",
        // endTime: this.value1[1] + " 23:59:59",
        ...this.page,
      };
      Object.assign(data, this.transformSearch());
      this.$axios({
        url: Config.dev_url + "/api-apps/WorkOrder/OrderRanking",
        method: "post",
        data,
      }).then((res) => {
        console.log(res);
        this.count = Number(res.data.count);
        let salvProName = [];
        let salvProValue = [];
        let newData = [];
        if (res.data.data.length === 0) {
          this.initEchart({ salvProName, salvProValue });
          return;
        }
        res.data.data.forEach((obj) => {
          salvProName.push(obj.createbyname);
          salvProValue.push(obj.a);
          if (!obj.tb) {
            if (Number(obj.tb) > 0) {
              newData.push({
                num: obj.tb + "%",
                trend: 1,
              });
            } else {
              newData.push({
                num: obj.tb + "%",
                trend: 2,
              });
            }
          } else {
            newData.push({
              num: obj.tb,
              trend: 0,
            });
          }
          this.newData = newData;
          console.log(this.newData);
          this.initEchart({ salvProName, salvProValue });
        });
      });
    },
    siteChange() {
      if (this.value1.length !== 0) {
        this.getData();
      }
    },
    timeChange() {
      console.log(this.value1);
      if (this.site !== "") {
        this.getData();
      }
    },
    initEchart(obj) {
      let vm = this;
      let { salvProName, salvProValue } = obj;
      // var _dataList =  JSON.parse(JSON.stringify(this.alarmWarningData3));
      // this.vm = echarts.init(this.$refs.main_modle13, "macarons"); //每月报警预警数
      //每月报警预警数
      // if(this.value.value == undefined){
      //     this.value.value =
      // }
      // var salvProName = salvProName;
      // var salvProValue = salvProValue;

      var option13 = {
        animation: true,
        backgroundColor: "white",
        grid: {
          left: "2%",
          right: "2%",
          bottom: "2%",
          top: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          extraCssText: "width:120px;height:80px",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            let str =
              params[0].name +
              " : " +
              params[0].value +
              "<br/>" +
              `<div>
            <div style="margin-bottom: 29%;display: flex;justify-content: space-between">
                <span style="color: rgba(0,0,0,0.45); font-size: 12px;">${vm.LangHandler('yoy','同比')}</span>`;

            if (vm.newData[params[0].dataIndex].trend == 1) {
              str += `<i class="el-icon-caret-top" style="color:#F53F3F; font-size: 12px;"></i>
                      <span style="color:#F53F3F;">${
                        vm.newData[params[0].dataIndex].num
                      }</span></div>`;
            } else if (vm.newData[params[0].dataIndex].trend == 2) {
              str += `<i class="el-icon-caret-top" style="color:green; font-size: 12px;"></i>
                      <span style="color:green;">${
                        vm.newData[params[0].dataIndex].num
                      }</span></div>`;
            } else {
              str += `<span style="font-weight: bold">${
                vm.newData[params[0].dataIndex].num
              }</span></div>`;
            }
            return str;
          },
        },
        xAxis: {
          show: true,
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          splitArea: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(134,144,156,1)",
            },
          },
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            axisLabel: {
              show: true,
              textStyle: {
                color: "rgba(134,144,156,1)",
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: salvProName.reverse(),
          },
          {
            type: "category",
            inverse: true,
            axisTick: "none",
            axisLine: "none",
            show: true,
            axisLabel: {
              textStyle: {
                color: "#ffffff",
                fontSize: "12",
              },
            },
            data: salvProValue.reverse(),
          },
        ],
        series: [
          {
            name: "值",
            type: "bar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            zlevel: 1,
            itemStyle: {
              normal: {
                color: "rgba(45,118,254,1)",
              },
            },
            label: {
              show: true,
              position: "right",
            },
            barWidth: 10,
            data: salvProValue,
          },
        ],
      };

      // this.vm.setOption(option13);
      this.updateChart("chart1", "chartOption1", option13);
    },
    updateChart: function (refName, optionName, option13 = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option13;
      setTimeout(() => {
        this.$refs.main_modle13.updateChartView();
      }, 500);
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.operationAm2 {
  .card-content1 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
  .card-content2 {
    height: 200px;
    width: 100%;
    .subTable {
      height: 100px;
      display: flex;
      flex-direction: column;
      .subTable-list {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 44px;
        margin-top: 8px;
        box-shadow: 0px -1px 0px 0px #ebebeb inset;
        &:last-child {
          box-shadow: none;
        }
        span {
          display: flex;
        }
        i {
          width: 4px;
          height: 36px;
          margin-right: 6px;
          display: inline-block;
        }

        .percent-box {
          padding: 0 7px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
          text-align: center;
          border-radius: 10px;
          box-sizing: border-box;
          i {
            display: inline-block;
            margin-right: 15px;
          }
        }
        .up {
          background: #fdeaeb;
          color: #f03040;
        }
        .decline {
          background: #e8f5ea;
          color: #00b42a;
        }
      }
    }
  }
}
</style>
